
.bg-light {
  background-color: #f8f9fa!important;
}

.all-window {
  width: 100vw;
  height: 100vh;
}

.container-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-auto {
  margin: auto;
}

/* align text */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* end text align */

/* Spacing margin */

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.ml-1 {
  margin-left: .25rem;
}

.my-2 {
  margin-top: .50rem;
  margin-bottom: .50rem;
}

.mx-2 {
  margin-left: .50rem;
  margin-right: .50rem;
}

.mt-2 {
  margin-top: .50rem;
}

.mb-2 {
  margin-bottom: .50rem;
}

.mr-2 {
  margin-right: .50rem;
}

.ml-2 {
  margin-left: .50rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mr-3 {
  margin-right: 1rem;
}

.ml-3 {
  margin-left: 1rem;
}

.my-4 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mx-4 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mt-4 {
  margin-top: 1.25rem;
}

.mb-4 {
  margin-bottom: 1.25rem;
}

.mr-4 {
  margin-right: 1.25rem;
}

.ml-4 {
  margin-left: 1.25rem;
}

/* end spacing margin */

/* spacing padding */

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pl-1 {
  padding-left: .25rem;
}

.py-2 {
  padding-top: .50rem;
  padding-bottom: .50rem;
}

.px-2 {
  padding-left: .50rem;
  padding-right: .50rem;
}

.pt-2 {
  padding-top: .50rem;
}

.pb-2 {
  padding-bottom: .50rem;
}

.pr-2 {
  padding-right: .50rem;
}

.pl-2 {
  padding-left: .50rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pr-3 {
  padding-right: 1rem;
}

.pl-3 {
  padding-left: 1rem;
}

.py-4 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-4 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.pt-4 {
  padding-top: 1.25rem;
}

.pb-4 {
  padding-bottom: 1.25rem;
}

.pr-4 {
  padding-right: 1.25rem;
}

.pl-4 {
  padding-left: 1.25rem;
}

/* end spacing padding */

